@import url("https://use.typekit.net/irb0lem.css");

body{
    background-color:#ededed !important;
}
.table__rel{
    background-color:cadetblue;
    color:white;
}
.table__prom{
    background-color:silver;
    color:white;
}
.slick-list{
    min-width:100%;
}