@import '../../settings';


.SideSlider{
	width: 75vw;
	height: calc(100% - 75px) ;
	position: fixed;
	top:rem-calc(75);
	right:0;
	background-color: $white;
	padding: rem-calc(50);
	transform: translateX(100%);
	z-index: 10;
	transition: 0.3s;
	box-shadow: -10px 0px 10px 0px rgba(0,0,0,0.18);
	&.open{
		transform: translateX(0%);
		transition: 0.3s;
	}
	.scrollY{
		overflow-y: scroll;
		height: 60vh;
	}
	.success{
		color: $success-color;
	}
	.alert{
		color: $alert-color;
	}
	.Sidebar{
		font-size: rem-calc(24);
		width: rem-calc(50);
		height: rem-calc(50);
		position:absolute;
		top:30vh;
		left: -50px;
		background-color:$white;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: rem-calc(10) 0 0 rem-calc(10);
		&:hover{
			cursor: pointer;
		}
		svg{
			cursor: pointer;
			transition: .3s;
			&:hover{
				opacity: .8;
				transition: .3s
			}
		}
	}
	button{
		&:hover{
			cursor: pointer;
		}
		svg{
			cursor: pointer;
			transition: .3s;
			&:hover{
				opacity: .8;
				transition: .3s
			}
		}
	}
}