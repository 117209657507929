@import '../settings';


.loginForm{
	min-height: 100vh;
	min-width: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	form{
		width: rem-calc(650);
		padding: rem-calc(50);
		border: 1px solid $medium-gray;
		background-color: $white;
	}
}